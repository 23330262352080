import { Component } from "react";
import { withRouter } from "react-router-dom";
import { Hub, Logger } from "aws-amplify";
import BasicTemplate from "../../templates/BasicTemplate";
import ConfirmationPage from "./ConfirmationPage";
import SignUpPage from "./SignUpPage";
import SignInPage from "./SignInPage";
import ForgotPasswordPage from "./ForgotPasswordPage";
import NewPasswordPage from "./NewPasswordPage";
import Titles from "../../../Resources/Titles";
import SiteContext from "../../../SiteContext";
import { PAGE_FROM } from "../../../Utils/Constant";

class AuthenticationPage extends Component {
  constructor(props) {
    super(props);

    this.logger = new Logger("Authenticator");
    this._isMounted = false;

    let locationState = this.props.location.state;

    const stateAction = locationState?.action
      ? locationState.action
      : props.match.params.action;

    this.state = {
      action: stateAction || "SIGN_IN",
      user: locationState?.user ? locationState.user : null,
      message: null,
      reserveData: locationState && locationState.reserveData,
      reserveInput: locationState && locationState.reserveInput,
      isLoading: false,
    };

    Hub.listen("auth", this.hubAuthListener);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    Hub.remove("auth", this.hubAuthListener);
    this._isMounted = false;
  }

  handleLoading = (isLoading) => {
    this._isMounted &&
      this.setState({
        isLoading: isLoading,
      });
  };
  static contextType = SiteContext;
  handleCheckAfterSignIn() {
    let locationState = this.props.location.state;
    const searchParams = new URLSearchParams(window.location.search);
    if (locationState && locationState.from === PAGE_FROM.RESERVE) {
      // TODO 最終的にはcontextValueでのみ管理する形にしたい
      if (this.state.reserveInput !== undefined) {
        this.props.history.push({
          pathname: `/reserve/input/${this.state.reserveInput.hospitalId}/${this.state.reserveInput.menuId}`,
          state: {
            from: PAGE_FROM.LOGIN,
            reserveData: this.state.reserveData,
            reserveInput: this.state.reserveInput,
          },
        });
      } else {
        const contextValue = this.context;
        this.props.history.push({
          pathname: `/reserve/input/${contextValue.reserveInput.hospitalId}/${contextValue.reserveInput.menuId}`,
          state: {
            from: PAGE_FROM.LOGIN,
            reserveData: contextValue.reserveData,
            reserveInput: contextValue.reserveInput,
          },
        });
      }
    } else if (locationState && locationState.from === PAGE_FROM.TOP) {
      this.props.history.push({
        pathname: "/",
        state: {
          from: PAGE_FROM.LOGIN,
        },
      });
    } else if (locationState && locationState.from === PAGE_FROM.LOGIN) {
      this.props.history.push({
        pathname: "/",
        state: {
          from: PAGE_FROM.LOGIN,
        },
      });
    } else if (locationState && locationState.from === PAGE_FROM.SIGNUP) {
      this.props.history.push({
        pathname: "/",
        state: {
          from: PAGE_FROM.LOGIN,
        },
      });
    } else if (
      locationState &&
      locationState.from === PAGE_FROM.TERMS_OF_SERVICE
    ) {
      this.props.history.push({
        pathname: "/terms-of-service",
        state: {
          from: PAGE_FROM.LOGIN,
        },
      });
    } else if (
      locationState &&
      locationState.from === PAGE_FROM.CONSUMER_POLICY
    ) {
      this.props.history.push({
        pathname: "/consumer-policy",
        state: {
          from: PAGE_FROM.LOGIN,
        },
      });
    } else if (
      locationState &&
      locationState.from === PAGE_FROM.PRIVACY_POLICY
    ) {
      this.props.history.push({
        pathname: "/privacy-policy",
        state: {
          from: PAGE_FROM.LOGIN,
        },
      });
    } else if (
      locationState &&
      locationState.from === PAGE_FROM.SEARCH_HOSPITAL
    ) {
      this.props.history.push({
        pathname: "/search",
        search: "?type=hospital",
        state: {
          from: PAGE_FROM.LOGIN,
        },
      });
    } else if (
      locationState &&
      locationState.from === PAGE_FROM.SEARCH_PHARMACY
    ) {
      this.props.history.push({
        pathname: "/search",
        search: "?type=pharmacy",
        state: {
          from: PAGE_FROM.LOGIN,
        },
      });
    } else if (locationState && locationState.from === PAGE_FROM.SEARCH_LIST) {
      this.props.history.push({
        pathname: "/search/list",
        search: locationState.search,
        state: {
          from: PAGE_FROM.LOGIN,
        },
      });
    } else if (locationState && locationState.from === PAGE_FROM.HOSPITAL) {
      this.props.history.push({
        pathname: `/hospital/${locationState.hospitalId}`,
        state: {
          from: PAGE_FROM.LOGIN,
        },
      });
    } else if (
      (locationState && locationState.from === PAGE_FROM.ENTERPRISE) ||
      searchParams.get("prepage") === PAGE_FROM.ENTERPRISE ||
      this.context.fromSignUp === PAGE_FROM.ENTERPRISE
    ) {
      this.props.history.push({
        pathname: "/enterprise/authorize",
        state: {
          from: PAGE_FROM.LOGIN,
        },
      });
      if (this.context.fromSignUp === PAGE_FROM.ENTERPRISE) {
        this.context.fromSignUp = undefined;
      }
    } else {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const redirect = urlParams.get("redirect");
      this.props.history.push(redirect || "/");
    }
  }

  hubAuthListener = (data) => {
    switch (data.payload.event) {
      case "signIn":
        this.handleCheckAfterSignIn();
        break;
      case "signOut":
        this.props.history.push("/");
        break;
      case "signIn_failure":
        this.logger.error("user sign in failed");
        break;
      default:
        break;
    }
  };

  updateState = (action, user) => {
    if (this._isMounted) {
      this.setState({
        action: action,
        user: user ? user : null,
      });
      this.props.history.replace(this.props.location.pathname, {
        ...this.props.location.state,
        action,
        user: user ? user : null,
      });
    }
  };

  componentDidUpdate() {
    const locationState = this.props.location.state;
    if (locationState?.action) {
      if (
        locationState.action.toLowerCase() !== this.state.action.toLowerCase()
      ) {
        this.setState({
          action: locationState.action,
        });
      }
    } else {
      const action = this.props.match.params.action
        ? this.props.match.params.action
        : "SIGN_IN";

      this.setState({
        action,
      });
      this.props.history.replace(
        this.props.location.pathname + window.location.search,
        {
          ...this.props.location.state,
          action,
        }
      );
    }
  }

  showMessage = (message) => {
    if (this._isMounted) {
      this.setState({
        message,
      });
    }
  };

  render() {
    const action = this.state.action.toLowerCase();
    let authPage = null;
    let title = "";
    let subStyleTheme = "default";
    let showQRCode = true;
    switch (action) {
      case "signup":
        title = Titles.signUp;
        authPage = <SignUpPage authcb={this.updateState} />;
        break;
      case "confirmation":
        title = Titles.signUpConfirmation;
        showQRCode = false;
        authPage = (
          <ConfirmationPage authcb={this.updateState} user={this.state.user} />
        );
        break;
      case "forgot_password":
        title = Titles.forgotPassword;
        authPage = (
          <ForgotPasswordPage
            authcb={this.updateState}
            showMessage={this.showMessage}
          />
        );
        break;
      case "new_password_required":
        title = Titles.newPasswordRequired;
        authPage = (
          <NewPasswordPage user={this.state.user} authcb={this.updateState} />
        );
        break;
      default:
        subStyleTheme = "multiAction";
        title = Titles.singIn;
        authPage = (
          <SignInPage
            authcb={this.updateState}
            message={this.state.message}
            onLoading={this.handleLoading}
          />
        );
        break;
    }

    return (
      <BasicTemplate
        title={title}
        main={authPage}
        isLoading={this.state.isLoading}
        subStyleTheme={subStyleTheme}
        showQRCode={showQRCode}
      />
    );
  }
}

export default withRouter(AuthenticationPage);
