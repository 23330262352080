import React, { Component } from "react";
import VerifyEmailForm from "../organisms/VerifyEmailForm";
import BasicTemplate from "../templates/BasicTemplate";
import { withRouter } from "react-router-dom";
import Titles from "../../Resources/Titles";

class VerifyEmailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const main = <VerifyEmailForm {...this.props} />;
    return (
      <BasicTemplate
        title={Titles.verifyEmail}
        main={main}
        showQRCode={false}
      />
    );
  }
}

export default withRouter(VerifyEmailPage);
