import React from "react";
import SystemError from "../organisms/SystemError";
import BasicTemplate from "../templates/BasicTemplate";
import { redirectToTopForCanNotDirectAccess } from "../../Utils/redirectToTopForCanNotDirectAccess";

function SystemErrorPage() {
  const redirectPage = redirectToTopForCanNotDirectAccess();
  if (redirectPage !== undefined) {
    return redirectPage;
  }
  return <BasicTemplate main={<SystemError />} />;
}

export default SystemErrorPage;
