import React from "react";
import styled from "styled-components";
import QRCodeTag from "../atoms/others/QRCodeTag";
import QRCode from "qrcode.react";
import { japaneseList } from "../../Resources/japaneseList";
import { isLnln } from "../../Utils/checkLnln";
import { useLocation } from "react-router-dom";
import { PAGE_FROM } from "../../Utils/Constant";

const QRCodeImageContainer = styled.div`
  margin-top: 7px;
`;

const QRCodeImageBorder = styled.div`
  display: inline-block;
  padding: 10px;
  background-color: ${(p) => p.theme.color.fontInvert};
  width: 100px;
  height: 100px;
`;
function addQueryParams(baseUrl, params) {
  const url = new URL(baseUrl);
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  return url.toString();
}

export default () => {
  const currentOrigin = window.location.origin;
  const { pathname, search } = useLocation();
  const currentUrl = currentOrigin + pathname + search;
  const queryParams = isLnln()
    ? {
        utm_source: "pcview_qr",
        utm_medium: "inlink",
        utm_campaign: "lunalunaonlineuser",
      }
    : {
        utm_source: "pcview_qr",
        utm_medium: "inlink",
        utm_campaign: "caradaonlineuser",
      };
  const enterpriseParams = {
    prepage: PAGE_FROM.ENTERPRISE,
  };
  let QRCODE_CONTENT = addQueryParams(currentUrl, queryParams);
  const title = document.title;

  // TODO: 404ページを切り出す際に修正
  if (title.indexOf("404 Not found") != -1) {
    QRCODE_CONTENT = addQueryParams(currentOrigin, queryParams);
  }

  if (window.history.state?.state?.from === PAGE_FROM.ENTERPRISE) {
    QRCODE_CONTENT = addQueryParams(QRCODE_CONTENT, enterpriseParams);
  }

  return (
    <QRCodeTag>
      <div>{japaneseList.organisms.QRCode.export.QRCodeTag.div.j001}</div>
      <div>{japaneseList.organisms.QRCode.export.QRCodeTag.div.j002}</div>
      <QRCodeImageContainer>
        <QRCodeImageBorder>
          <QRCode value={QRCODE_CONTENT} style={{ height: 80, width: 80 }} />
        </QRCodeImageBorder>
      </QRCodeImageContainer>
    </QRCodeTag>
  );
};
