import React from "react";

const SiteContext = React.createContext({
  /**
   * Site UI display mode.
   *
   * - (null) : default.
   * - "lnln" : "LunaLuna app".
   */
  ui: null,
  /** token */
  token: null,

  /** clinic id (used for transition to lnln site after reservation) */
  lnln_clinicId: null,

  /** reserveData (used for after login ,the information entered in the reservation page is set */
  reserveData: null,
  /** reserveInput (used for after login ,the information entered in the reservation page is set */
  reserveInput: null,
  /** used to display a registration completion pop-up box after user registration */
  isLoading: null,
/** In the invitation code sign up process at Luna office, scanning the QR code with a smartphone also follows the invitation code input steps. */
  fromSignUp: null,
});

/**
 * This context is used by global state
 */
export default SiteContext;
