/** Color rules */
export const color = {
  primary: "#27cdcb",
  primaryVariant: "#6ce1d5",
  primaryVariant2: "#d3f6f2",
  primaryVariant3: "#eafbf9",
  primaryVariant4: "#f2feff",
  secondary: "#cccccc",
  secondaryVariant: "#f1f3f3",
  secondaryVariant2: "#f7f7f7",
  caution: "#ff4d4d",
  warning: "#ff4d4d",
  warningBackground: "#fff0f0",
  font: "#393939",
  fontVariant: "#999999",
  fontInvert: "#ffffff",
  examIcon1: "#faae1c",
  examIcon2: "#85b7de",
  background: "#f1fcfa",
  backgroundVariant1: "#eafbf9",
};

export const header = {
  bg: "#ffffff",
  border: "#f1f3f3",
};

export const button = {
  font: {
    color: {
      default: "#fff",
      disabled: "#cccccc",
    },
  },
  background: {
    color: {
      default: "#27cdcb",
      disabled: "#f1f3f3",
      /* this button color to enhance the conversion rate. */
      cvbutton: "#f9a010",
    },
  },
  padding: {
    s: "10px",
    default: "10px 20px",
    m: "10px 20px",
  },
};

/** heading rules */
export const heading = {
  h1: {
    color: "#ffffff",
    bg: "#27cdcb",
  },
  h2: {
    color: "#27cdcb",
    bg: "#eafbf9",
  },
  h3: {
    color: "#393939",
    border: "#27cdcb",
  },
};

/** font rules */
export const font = {
  color: {
    default: "#393939",
    therd: "#27cdcb",
    accent: "#27cdcb",
    link: "#27cdcb",
    gray: "#999999",
    note: "#ff4d4d",
    error: "#ff4d4d",
    qr: "#54544f",
    white: "#fff",
    orange: "#f9a011",
    darkGray: "#393939",
    black: "#333333",
    insuranceNote: "#f26666", //TODO:noteと共通化すべき
  },
  size: {
    sss: "0.7rem",
    ss: "0.8rem",
    s: "0.9rem",
    default: "1rem",
    m: "1rem",
    l: "1.2rem",
    ll: "1.5rem",
  },
  align: {
    default: "left",
    center: "center",
    left: "left",
    right: "right",
  },
  weight: {
    default: "normal",
    normal: "normal",
    bold: "bold",
  },
};

export const icon = {
  course: {
    color: "#ffffff",
    bg: "#27cdcb",
  },
  border: {
    color: "#999",
  },
  insurance: {
    bg: "#ffffff",
  },
};

export const radio = {
  border: "#999999",
  checked: "#6ce1d5",
};

export const checkbox = {
  bg: "#f1f3f3",
  border: "#999999",
  checked: "#27cdcb",
  checkedborder: "#27cdcb",
};

export const fontSize = {
  large: "1.5rem",
  medium: "1rem",
  small: "0.5rem",
};

export const space = {
  short: "10px",
  normal: "15px",
  medium: "20px",
  long: "30px",
};

export const rgbaColor = {
  dark: "rgba(0, 0, 0, 0.2)",
  exDark: "rgba(0, 0, 0, 0.65)",
};

export const panel = {
  padding: {
    s3: "5px",
    ss: "10px",
    s: "15px",
    default: "20px",
    m: "20px",
    l: "30px",
    ll: "40px",
    ts: "15px 0 0 0",
    bs: "0 0 15px 0",
    bm: "0 0 20px 0",
    vsh0: "15px 0", // virtical horizon
    vmh0: "20px 0",
    vlh0: "30px 0",
    v0hs: "0 15px",
    v0hm: "0 20px",
  },
  align: {
    default: "left",
    center: "center",
    left: "left",
    right: "right",
  },
  background: {
    color: {
      default: "#fff",
      note: "#fff6f6",
      therd: "#eafbf9",
      qr: "#bff1eb",
    },
  },
  border: {
    color: {
      note: "#ff4d4d",
      default: "#f1f3f3",
      insuranceNote: "#f26666",
    },
  },
};

export const loginNotification = {
  background: "#fff6f6",
  color: "#f26666",
};

export const thumbnailItem = {
  borderColor: "#d8d6d6",
  backgroundColor: "#ededf0",
};

export const cancelPanel = {
  backgroundColor: "#eafbf9",
};

export const irregularAppointment = {
  color: "#27cdcb",
};

export const lnlnOffice = {
  skyBlue1: "#def1fb",
  skyBlue2: "#f2fafe",
  skyBlue3: "#e9f5fc",
  darkBlue1: "#464883",
  darkBlue2: "#171f55",
  smokyBlue: "#95abd8",
  white: "#ffffff",
};
