import React, { Component } from "react";
import styled from "styled-components";
import Panel from "../../atoms/panels/Panel";
import PanelCaution from "../../atoms/panels/PanelCaution";

import FontP from "../../atoms/fonts/FontP";

import { isLnln } from "../../../Utils/checkLnln";

import IconPath from "../../../images/carada/insuranceCard/cyuui_icon.svg";
import IconPathLnln from "../../../images/lnln/insuranceCard/cyuui_icon.svg";
import IconOkPath from "../../../images/carada/insuranceCard/maru.svg";
import IconOkPathLnln from "../../../images/lnln/insuranceCard/maru.svg";
import IconNgPath from "../../../images/carada/insuranceCard/batsu.svg";
import IconNgPathLnln from "../../../images/lnln/insuranceCard/batsu.svg";
import IconOkExamplePath from "../../../images/carada/insuranceCard/good.svg";
import IconOkExamplePathLnln from "../../../images/lnln/insuranceCard/good.svg";
import IconNgExample1Path from "../../../images/carada/insuranceCard/bad01.png";
import IconNgExample1PathLnln from "../../../images/lnln/insuranceCard/bad01.png";
import IconNgExample2Path from "../../../images/carada/insuranceCard/bad02.png";
import IconNgExample2PathLnln from "../../../images/lnln/insuranceCard/bad02.png";
import IconNgExample3Path from "../../../images/carada/insuranceCard/bad03.png";
import IconNgExample3PathLnln from "../../../images/lnln/insuranceCard/bad03.png";
import IconNgExample4Path from "../../../images/carada/insuranceCard/bad04.png";
import IconNgExample4PathLnln from "../../../images/lnln/insuranceCard/bad04.png";
import IconNgExsampl5Path from "../../../images/carada/insuranceCard/bad05.png";
import IconNgExample5PathLnln from "../../../images/lnln/insuranceCard/bad05.png";
import IconNgExample6Path from "../../../images/carada/insuranceCard/bad06.png";
import IconNgExample6PathLnln from "../../../images/lnln/insuranceCard/bad06.png";
import DocumentsNgMyNumberPath from "../../../images/carada/insuranceCard/bad_myNumber.svg";
import DocumentsNgMyNumberPathLnln from "../../../images/lnln/insuranceCard/bad_myNumber.svg";
import DocumentsNgMedicalCertificatePath from "../../../images/carada/insuranceCard/bad_medicalCertificate.svg";
import DocumentsNgMedicalCertificatePathLnln from "../../../images/lnln/insuranceCard/bad_medicalCertificate.svg";

import { japaneseList } from "../../../Resources/japaneseList";
const Resources = japaneseList.organisms.mypage.InsuranceCardCaution;

const iconPath = isLnln() ? IconPathLnln : IconPath;
const iconOkPath = isLnln() ? IconOkPathLnln : IconOkPath;
const iconNgPath = isLnln() ? IconNgPathLnln : IconNgPath;
const iconOkExamplePath = isLnln() ? IconOkExamplePathLnln : IconOkExamplePath;
const iconNgExample1Path = isLnln()
  ? IconNgExample1PathLnln
  : IconNgExample1Path;
const iconNgExample2Path = isLnln()
  ? IconNgExample2PathLnln
  : IconNgExample2Path;
const iconNgExample3Path = isLnln()
  ? IconNgExample3PathLnln
  : IconNgExample3Path;
const iconNgExample4Path = isLnln()
  ? IconNgExample4PathLnln
  : IconNgExample4Path;
const iconNgExample5Path = isLnln()
  ? IconNgExample5PathLnln
  : IconNgExsampl5Path;
const iconNgExample6Path = isLnln()
  ? IconNgExample6PathLnln
  : IconNgExample6Path;

const documentsNgMyNumberPath = isLnln()
  ? DocumentsNgMyNumberPathLnln
  : DocumentsNgMyNumberPath;
const documentsNgMedicalCertificatePath = isLnln()
  ? DocumentsNgMedicalCertificatePathLnln
  : DocumentsNgMedicalCertificatePath;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;
const CustomPanelCaution = styled(PanelCaution)`
  padding: 15px;
  border: solid 1px ${(p) => p.theme.panel.border.color.insuranceNote};
  cursor: pointer;
`;
const CustomFontP = styled(FontP)`
  position: relative;
  display: flex;
  &:before {
    content: "";
    position: absolute;
    top: 10px;
    right: 7px;
    display: block;
    width: 10px;
    height: 1px;
    background-color: ${(p) => p.theme.panel.border.color.insuranceNote};
    transform: rotate(45deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    display: block;
    width: 10px;
    height: 1px;
    background-color: ${(p) => p.theme.panel.border.color.insuranceNote};
    transform: rotate(-45deg);
  }
  &.isOpen {
    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
  }
`;
const CautionText = styled(FontP)`
  padding-top: 10px;
  margin: 10px 0;
`;
const Box = styled.div`
  margin: 15px 0;
  padding: 15px;
  color: ${(p) => p.theme.font.color.default};
  background-color: #fff;
  border-radius: 4px;
`;
const BoxOkTitle = styled(FontP)`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;
  color: ${(p) => p.theme.font.color.accent};
`;
const BoxOkItem = styled.div`
  display: flex;
`;
const BoxOkImgWrapper = styled.div`
  display: flex;
  padding: 10px 10px 20px 10px;
  width: 100%;
  justify-content: center;
`;
const BoxOkImg = styled.img`
  flex-basis: 50%;
`;

const BoxNgImagesFlex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const BoxTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const BoxNgTitle = styled(FontP)`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
`;

const BoxIcon = styled(Icon)`
  flex-shrink: 0;
`;

const BoxNgDocumentsFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const BoxNgDocumentsItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;

const BoxNgDocumentsTitleWrapper = styled.div`
  display: flex;
`;

const BoxNgDocumentsDescriptionWrapper = styled.div`
  flex: 6.5;
`;

const BoxNgDocumentsImg = styled.img`
  flex: 3.5;
`;

const BoxNgImagesItem = styled.div`
  flex-basis: 30%;
  margin-bottom: 10px;
`;
const BoxNgImagesImg = styled.img`
  display: block;
  width: 100%;
  margin-bottom: 3px;
`;

class InsuranceCardCaution extends Component {
  static defaultProps = {
    isOpen: false,
  };

  handleClick = (e) => {
    this.props.handleChangeIsOpen(!this.props.isOpen);
  };

  render() {
    return (
      <Panel>
        <CustomPanelCaution onClick={this.handleClick}>
          <CustomFontP
            size="s"
            weight="bold"
            color="insuranceNote"
            className={this.props.isOpen && "isOpen"}
          >
            <Icon src={iconPath} />
            {Resources.title}
          </CustomFontP>
          {this.props.isOpen && (
            <React.Fragment>
              <CautionText size="ss" color="black">
                {Resources.text}
              </CautionText>

              <Box>
                <BoxTitleWrapper>
                  <BoxIcon src={iconOkPath} />
                  <BoxOkTitle size="s">{Resources.ok.title}</BoxOkTitle>
                </BoxTitleWrapper>
                <BoxOkItem>
                  <BoxOkImgWrapper>
                    <BoxOkImg src={iconOkExamplePath} />
                  </BoxOkImgWrapper>
                </BoxOkItem>
                <BoxOkItem>
                  <FontP size="ss" color="black">
                    {Resources.ok.text}
                  </FontP>
                </BoxOkItem>
              </Box>

              <Box>
                <BoxTitleWrapper>
                  <BoxIcon src={iconNgPath} />
                  <BoxNgTitle size="s" color="insuranceNote">
                    {Resources.ng.documents.title}
                  </BoxNgTitle>
                </BoxTitleWrapper>

                <BoxNgDocumentsFlex>
                  <BoxNgDocumentsTitleWrapper>
                    <FontP size="s" weight="bold" color="black">
                      {Resources.ng.documents.myNumberSubTitle}
                    </FontP>
                  </BoxNgDocumentsTitleWrapper>
                  <BoxNgDocumentsItem>
                    <BoxNgDocumentsImg src={documentsNgMyNumberPath} />
                    <BoxNgDocumentsDescriptionWrapper>
                      <FontP size="ss" color="black">
                        {Resources.ng.documents.myNumberDescription}
                      </FontP>
                    </BoxNgDocumentsDescriptionWrapper>
                  </BoxNgDocumentsItem>
                  <BoxNgDocumentsTitleWrapper>
                    <FontP size="s" weight="bold" color="black">
                      {Resources.ng.documents.medicalCertificateSubtitle}
                    </FontP>
                  </BoxNgDocumentsTitleWrapper>
                  <BoxNgDocumentsItem>
                    <BoxNgDocumentsImg
                      src={documentsNgMedicalCertificatePath}
                    />
                    <BoxNgDocumentsDescriptionWrapper>
                      <FontP size="ss" color="black">
                        {Resources.ng.documents.medicalCertificateDescription}
                      </FontP>
                    </BoxNgDocumentsDescriptionWrapper>
                  </BoxNgDocumentsItem>
                </BoxNgDocumentsFlex>

                <BoxTitleWrapper>
                  <BoxIcon src={iconNgPath} />
                  <BoxNgTitle size="s" color="insuranceNote">
                    {Resources.ng.images.title}
                  </BoxNgTitle>
                </BoxTitleWrapper>

                <BoxNgImagesFlex>
                  <BoxNgImagesItem>
                    <BoxNgImagesImg src={iconNgExample1Path} />
                    <FontP size="sss" align="center" color="black">
                      {Resources.ng.images.example1}
                    </FontP>
                  </BoxNgImagesItem>
                  <BoxNgImagesItem>
                    <BoxNgImagesImg src={iconNgExample2Path} />
                    <FontP size="sss" align="center" color="black">
                      {Resources.ng.images.example2}
                    </FontP>
                  </BoxNgImagesItem>
                  <BoxNgImagesItem>
                    <BoxNgImagesImg src={iconNgExample3Path} />
                    <FontP size="sss" align="center" color="black">
                      {Resources.ng.images.example3}
                    </FontP>
                  </BoxNgImagesItem>
                  <BoxNgImagesItem>
                    <BoxNgImagesImg src={iconNgExample4Path} />
                    <FontP size="sss" align="center" color="black">
                      {Resources.ng.images.example4}
                    </FontP>
                  </BoxNgImagesItem>
                  <BoxNgImagesItem>
                    <BoxNgImagesImg src={iconNgExample5Path} />
                    <FontP size="sss" align="center" color="black">
                      {Resources.ng.images.example5}
                    </FontP>
                  </BoxNgImagesItem>
                  <BoxNgImagesItem>
                    <BoxNgImagesImg src={iconNgExample6Path} />
                    <FontP size="sss" align="center" color="black">
                      {Resources.ng.images.example6}
                    </FontP>
                  </BoxNgImagesItem>
                </BoxNgImagesFlex>
              </Box>
            </React.Fragment>
          )}
        </CustomPanelCaution>
      </Panel>
    );
  }
}

export default InsuranceCardCaution;
