import React, { Component } from "react";
import styled from "styled-components";
import { Auth, Hub } from "aws-amplify";
import { withRouter } from "react-router";

import { space } from "../../theme";

import FontPCenter from "../../atoms/fonts/FontPCenter";
import Heading1 from "../../atoms/headings/Heading1";
import Heading1LnlnOffice from "../../atoms/headings/Heading1LnlnOffice";
import Heading3 from "../../atoms/headings/Heading3";
import FullWidthButton from "../../atoms/buttons/ButtonFullWidth";
import ButtonOutlined from "../../atoms/buttons/ButtonOutlined";

import { japaneseList } from "../../../Resources/japaneseList";
import {
  ENTERPRISE_REGISTER_STEP,
  ENTERPRISE_SIGNUP_STEPS,
} from "../../../Utils/Constant";
import { EnterpriseStepperForm } from "../enterprise/EnterpriseStepperForm";
import LoadingScreen from "../../molecules/others/LoadingScreen";
import { PAGE_FROM } from "../../../Utils/Constant";
import SiteContext from "../../../SiteContext";

const TitleTop = styled.div`
  padding: 20px 14px 10px;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
`;

const InputTel = styled.input`
  height: 35px;
  width: calc(100% - 28px);
  margin-left: 14px;
  margin-right: 14px;
  color: ${(p) => p.theme.color.font};
  border: 1px solid ${(p) => p.theme.color.fontVariant};
  padding: 6px 5px;
  font-size: 16px;
  background-color: #f7f7f7;
  border: solid 1px #c3c3c3;
  border-radius: 0;
  -webkit-appearance: none;
  :focus {
    background-color: #fff;
    outline: 0;
  }
`;

const CodeTitle = styled.div`
  margin: 10px 14px 5px;
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
`;

const ConfirmButton = styled.div`
  margin: 0 ${space.medium} ${space.medium} ${space.medium};
  text-align: center;
  padding-top: 30px;
  margin: 0 30px 23px;
`;

const ConfirmFullWidthButton = styled(FullWidthButton)`
  height: 50px;
`;

const SendLetterButton = styled.div`
  margin: 0 ${space.medium} ${space.medium} ${space.medium};
  text-align: center;
  margin-bottom: 40px;
`;

const ResendButton = styled(ButtonOutlined)`
  padding: 4px 10px;
  font-size: 14px;
  border-radius: 4px;
`;

class ConfirmationForm extends Component {
  constructor(props) {
    super(props);
    this.inputs = {};
    this._isMounted = false;
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange = (evt) => {
    this.inputs = this.inputs || {};
    const { name, value } = evt.target;
    this.inputs[name] = value;
  };

  confirm = () => {
    const confirmResource = japaneseList.organisms.ConfirmationForm.confirm;

    const { code } = this.inputs;
    if (!this.checkInput()) {
      return;
    }

    Auth.confirmSignUp(this.props.user.username, code)
      .then(() => {
        this.setState({
          isLoading: true,
        });
        Hub.listen("auth", ({ payload }) => {
          const { event } = payload;
          if (event === "autoSignIn") {
            this.setState({
              isLoading: false,
            });
            this.props.history.push({
              search: window.location.search,
              state: {
                from:
                  this.props.location.state && this.props.location.state.from,
                flow: "signup",
                reserveData:
                  this.props.location.state &&
                  this.props.location.state.reserveData,
                reserveInput:
                  this.props.location.state &&
                  this.props.location.state.reserveInput,
                search: this.props.location.state.search,
                hospitalId: this.props.location.state.hospitalId,
              },
            });
          }
        });
      })
      .catch((err) => {
        let msg = err.message;
        if (err.code === "CodeMismatchException") {
          msg = confirmResource.j001;
        }
        this.snackBar(msg);
      });
  };

  reSend = (e) => {
    // remove default event
    e.preventDefault();
    const resendResource = japaneseList.organisms.ConfirmationForm.resend;
    const username = this.props.user.username;
    Auth.resendSignUp(username)
      .then(() => {
        console.log("code resent");
        this.snackBar("認証コードを送信しました。");
      })
      .catch((err) => {
        let msg = err.message;
        if (err.code === "CodeMismatchException") {
          msg = resendResource.j001;
        }
        this.snackBar(msg);
      });
  };

  snackBar = (msg, level = "error") => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: msg, level: level } },
      "SignUp"
    );
  };

  checkInput = () => {
    const checkInputResource =
      japaneseList.organisms.ConfirmationForm.checkInput;
    const { code } = this.inputs;
    if (!code) {
      this.snackBar(checkInputResource.j001);
      return false;
    }
    return true;
  };
  static contextType = SiteContext;
  render() {
    const renderResource = japaneseList.organisms.ConfirmationForm.render;
    const isFromEnterprise =
      (this.props.location.state &&
        this.props.location.state.from === PAGE_FROM.ENTERPRISE) ||
      this.context.fromSignUp === PAGE_FROM.ENTERPRISE;
    const title =
      renderResource.Fragment.div.FormAreaCenter.Typography5Center.j001.replace(
        /{{patientInfo.phoneNumber}}/g,
        this.props.user.phone_number
      );
    const headerText = renderResource.Fragment.PrimaryHeading.j001;
    return (
      <React.Fragment>
        <LoadingScreen isLoading={this.state.isLoading} />
        {isFromEnterprise ? (
          <>
            <Heading1LnlnOffice>{headerText}</Heading1LnlnOffice>
            <EnterpriseStepperForm
              registerSteps={ENTERPRISE_SIGNUP_STEPS}
              currentStep={
                ENTERPRISE_SIGNUP_STEPS[ENTERPRISE_REGISTER_STEP.SIGNUP]
              }
            />
          </>
        ) : (
          <Heading1>{headerText}</Heading1>
        )}
        <FontPCenter>
          <TitleTop>{title}</TitleTop>
        </FontPCenter>
        <FontPCenter>
          <CodeTitle>
            {renderResource.Fragment.div.div.FormArea.InputTitle.j001}
          </CodeTitle>
          <InputTel
            name="code"
            placeholder={
              renderResource.Fragment.div.div.FormArea.InputText.j001
            }
            onChange={this.handleInputChange}
          />
        </FontPCenter>

        <ConfirmButton>
          {/* GTM集計を導入している以下コンポーネントに子要素を追加する場合は必ずpointerEvents: "none"をstyleで追加すること */}
          <ConfirmFullWidthButton
            onClick={this.confirm}
            id="verification-code-confirm-button"
          >
            {renderResource.Fragment.div.div.ButtonArea.FullWidthButton.j001}
          </ConfirmFullWidthButton>
        </ConfirmButton>

        <SendLetterButton>
          {/* GTM集計を導入している以下コンポーネントに子要素を追加する場合は必ずpointerEvents: "none"をstyleで追加すること */}
          <ResendButton
            onClick={this.reSend}
            id="verification-code-resend-button"
          >
            {renderResource.Fragment.div.div.ButtonArea.FullWidthButtonSub.j001}
          </ResendButton>
        </SendLetterButton>
        <Heading3>{renderResource.Fragment.MiddleHeading.j001}</Heading3>
      </React.Fragment>
    );
  }
}
export default withRouter(ConfirmationForm);
