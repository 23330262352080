import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";
import styled from "styled-components";
import Heading1 from "../../atoms/headings/Heading1";
import Panel from "../../atoms/panels/Panel";
import FontP from "../../atoms/fonts/FontP";
import InputFile from "../../atoms/forms/InputFile";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import Image from "../../atoms/others/Image";
import InsuranceCardCaution from "./InsuranceCardCaution";
import {
  Account,
  GetInsuranceCardPresignedUrl,
} from "../../../Utils/GraphQLQueries";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { isWebView } from "../../device";

import { japaneseList } from "../../../Resources/japaneseList";
const Resources = japaneseList.organisms.mypage.InsuranceCardDetail;
const DATE_FORMAT = "YYYY/MM/DD HH:mm";
const INSURANCE_CARD_DETAIL_URL_LNLN = "/my-page/insurance-card?lnln_ob=1";

const ImageBox = styled.div`
  padding: 0 40px;
  text-align: center;
`;
const CustomImage = styled(Image)`
  max-height: 200px;
  object-fit: contain;
`;

const CustomInputFile = styled(InputFile)`
  display: none;
`;

const ContentWrapper = styled.div`
  padding-bottom: 20px;
`;

const CustomPanel = styled(Panel)`
  padding: 30px 30px 20px 30px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0px 15px;
`;

const CustomButtonFullWidth = styled(ButtonFullWidth)`
  font-size: 1rem;
  line-height: 30px;
`;

class InsuranceCardDetail extends Component {
  static defaultProps = {
    file: {},
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      file: {},
      existFilePath: "",
      isOpen: true,
      updatedTime: "",
      fileType: "",
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    await this.getPatientData();
  };

  getPatientData = async () => {
    if (this._isMounted) {
      const { data } = await GraphQLClient.query({ query: Account });
      if (data.account.insuranceCards.length !== 0) {
        let filePath =
          data &&
          data.account.insuranceCards[data.account.insuranceCards.length - 1]
            .filePath;
        let updatedTime =
          data &&
          data.account.insuranceCards[data.account.insuranceCards.length - 1]
            .updatedTime;
        let fileType =
          data &&
          data.account.insuranceCards[data.account.insuranceCards.length - 1]
            .fileType;
        const response = await GraphQLClient.mutate({
          mutation: GetInsuranceCardPresignedUrl,
          variables: { filePath },
        });
        Promise.all([data, response]).then((value) => {
          this.setState({
            existFilePath:
              value[1].data.getInsuranceCardPresignedUrl.getSignedRequest,
            updatedTime,
            fileType,
            isOpen: false,
          });
        });
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClickButton = (e) => {
    // case of lnln app, start external browser
    if (isWebView()) {
      window.location.href = INSURANCE_CARD_DETAIL_URL_LNLN;
      return;
    }
    // default event stop
    e.preventDefault();
    // Open file select
    document.getElementById("inputInsuranceCard").click();
  };

  onClickChangeIsOpen = (isOpen) => {
    this.setState({
      isOpen: isOpen,
    });
  };

  render() {
    const { handleSelectInsuranceCard } = this.props;
    return (
      <React.Fragment>
        <Heading1>{Resources.title}</Heading1>
        {!this.state.existFilePath && (
          <CustomPanel>
            <FontP size="s" align="center" weight="bold" color="black">
              {Resources.noFile}
            </FontP>
          </CustomPanel>
        )}
        <Panel>
          <ContentWrapper>
            <FontP size="s" color="black">
              {Resources.description}
            </FontP>
          </ContentWrapper>
          <FontP size="ss" color="insuranceNote">
            {Resources.note}
          </FontP>
        </Panel>
        {this.state.existFilePath && (
          <React.Fragment>
            <ImageBox>
              <CustomImage src={this.state.existFilePath} />
              <FontP size="ss" align="center" color="gray">
                {Resources.updateDate}:{" "}
                {format(this.state.updatedTime, DATE_FORMAT)}
              </FontP>
            </ImageBox>
          </React.Fragment>
        )}
        <InsuranceCardCaution
          handleChangeIsOpen={this.onClickChangeIsOpen}
          isOpen={this.state.isOpen}
        />
        <Panel>
          <CustomInputFile
            id={"inputInsuranceCard"}
            onChange={(e) => handleSelectInsuranceCard(e)}
          />
          <ButtonWrapper>
            <CustomButtonFullWidth onClick={this.handleClickButton}>
              {Resources.button}
            </CustomButtonFullWidth>
          </ButtonWrapper>
          {isWebView() && (
            <FontP size="s" align="center" color="note">
              {Resources.lnlntext}
            </FontP>
          )}
        </Panel>
      </React.Fragment>
    );
  }
}

export default withRouter(InsuranceCardDetail);
