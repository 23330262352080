import React, { useState, useEffect } from "react";
import Heading1LnlnOffice from "../../atoms/headings/Heading1LnlnOffice";
import BasicTemplate from "../../templates/BasicTemplate";
import { EnterpriseAuthorizationForm } from "../../organisms/enterprise/EnterpriseAuthorizationForm";
import Titles from "../../../Resources/Titles";
import { japaneseList } from "../../../Resources/japaneseList";
import { useAuthContext } from "../../../contexts/AuthContext";
import { EnterpriseStepperForm } from "../../organisms/enterprise/EnterpriseStepperForm";
import {
  ENTERPRISE_REGISTER_STEP,
  ENTERPRISE_SIGNIN_STEPS,
  ENTERPRISE_SIGNUP_STEPS,
} from "../../../Utils/Constant";

export const EnterpriseAuthorizationPage = (props) => {
  const renderResource = japaneseList.pages.EnterpriseAuthorizationPage.render;
  const [isLoading, setIsLoading] = useState(true);
  const isFlowSignUp =
    props.location.state && props.location.state.flow === "signup";
  const currentStep = isFlowSignUp
    ? ENTERPRISE_SIGNUP_STEPS[ENTERPRISE_REGISTER_STEP.AUTHORIZATION]
    : ENTERPRISE_SIGNIN_STEPS[ENTERPRISE_REGISTER_STEP.AUTHORIZATION];

  const registerSteps = isFlowSignUp
    ? ENTERPRISE_SIGNUP_STEPS
    : ENTERPRISE_SIGNIN_STEPS;

  const authContext = useAuthContext();
  let loading = true;
  if (
    authContext.isAuthenticated ||
    window.history.state?.state === undefined
  ) {
    loading = false;
  }
  useEffect(() => {
    if (authContext.isLoading || loading) return;
    if (!authContext.isAuthenticated) {
      props.history.push("/enterprise/authenticate");
      return;
    }
    setIsLoading(false);
  }, [authContext.isLoading]);

  const main = (
    <React.Fragment>
      <Heading1LnlnOffice>{renderResource.Title}</Heading1LnlnOffice>
      <EnterpriseStepperForm
        registerSteps={registerSteps}
        currentStep={currentStep}
      />
      <EnterpriseAuthorizationForm />
    </React.Fragment>
  );
  return (
    <BasicTemplate
      title={Titles.EnterpriseAuthorization}
      main={main}
      isLoading={isLoading}
      isNoIndex={true}
    />
  );
};
