import React from "react";
import AuthenticatedTemplate from "../../templates/AuthenticatedTemplate";
import { InquirySignupSmsCompleted } from "../../organisms/auth/InquirySignupSmsCompleted";
import Titles from "../../../Resources/Titles";
import { redirectToTopForCanNotDirectAccess } from "../../../Utils/redirectToTopForCanNotDirectAccess";

export const InquirySignupSmsCompletedPage = () => {
  const redirectPage = redirectToTopForCanNotDirectAccess();
  if (redirectPage !== undefined) {
    return redirectPage;
  }
  return (
    <AuthenticatedTemplate
      title={Titles.inquirySignupSmsCompleted}
      main={<InquirySignupSmsCompleted />}
    />
  );
};
