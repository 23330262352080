import { useHistory } from "react-router-dom";

export const redirectToTopForCanNotDirectAccess = (props = null) => {
  const action = "POP";
  if (props !== null) {
    if (props.history.action === action) {
      props.history.push(`/`);
      return {
        render() {
          return null;
        },
      };
    }
  } else {
    const history = useHistory();
    if (history.action === action) {
      history.push(`/`);
      return {
        render() {
          return null;
        },
      };
    }
  }
};
