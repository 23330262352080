import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import CancellationError from "../../organisms/account/CancellationError";
import AuthenticatedTemplate from "../../templates/AuthenticatedTemplate";
import { redirectToTopForCanNotDirectAccess } from "../../../Utils/redirectToTopForCanNotDirectAccess";

function CancellationErrorPage() {
  const redirectPage = redirectToTopForCanNotDirectAccess();
  if (redirectPage !== undefined) {
    return redirectPage;
  }
  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  return (
    <AuthenticatedTemplate
      main={<CancellationError errorType={query.get("error")} />}
    />
  );
}

export default CancellationErrorPage;
